<template>
  <div class="address_box">
     <div class="title">
        <img @click="goblack" src="../../../../src/assets/image/black.png" alt="">
        <span>{{isAdd?'新增地址':'编辑地址'}}</span>
     </div>
     <div>
        <van-address-edit
        :area-list="areaList"
        :show-postal="false"
        :show-delete="!isAdd"
        show-set-default
        :address-info="AddressInfo"
        show-search-result
        :search-result="searchResult"
        :area-columns-placeholder="['请选择', '请选择', '请选择']"
        @save="onSave"
        @delete="onDelete"
        @change-detail="onChangeDetail"
        />
     </div>
    <bottomList></bottomList>
  </div>
</template>

<script>
import { areaList } from '@vant/area-data';
import { operateAddress, deleteAddress } from '@/api/order'
export default {
  name: "editAddress",

  data() {
    return {
       areaList: areaList,
       searchResult: [],
       AddressInfo: {},
       params: {
            id: 0,
            name: "",
            phone: "",
            details: "",
            area: "",
            isDefault: true,
            code: '',
        },
        isAdd: true, // 判断是否新增地址
    };
  },

  mounted() {
    if(this.$route.params.id) {
        this.isAdd = false
        // 编辑 赋值
        console.log(this.$route.params.obj)
        this.AddressInfo = JSON.parse(this.$route.params.obj)
        this.AddressInfo.addressDetail = this.AddressInfo.details
        this.AddressInfo.province = this.AddressInfo.area.split(' ')[0]
        this.AddressInfo.city = this.AddressInfo.area.split(' ')[1]
        this.AddressInfo.county = this.AddressInfo.area.split(' ')[2]
        this.AddressInfo.areaCode = this.AddressInfo.code
    } else {
        this.isAdd = true
    }
  },

  methods: {
    onSave(content) {
        console.log(content)
        this.params = {
            id: this.$route.params.id,
            name: content.name,
            phone: content.tel,
            details: content.addressDetail,
            area: content.province + ' ' + content.city + ' ' + content.county, 
            isDefault: content.isDefault,
            code: content.areaCode
        }
        // 新增地址
        operateAddress(this.params).then(res => {
            if (res.state == 200) {
                this.$layer.quicktips('地址保存成功')
                // 返回上一页
                this.$router.go(-1);
            }
        })
    },
    onDelete() {
      deleteAddress(this.$route.params.id).then(res => {
        if (res.state == 200) {
            this.$layer.quicktips('删除成功')
            // 返回上一页
            this.$router.go(-1);
        }
      })
    },
    onChangeDetail(val) {
    },
    // 返回上一页
    goblack() {
        // 返回上一页
       this.$router.go(-1);
    }
  },
};
</script>

<style lang="less" scoped>
.title{
    position: relative;
    text-align: center;
    padding: 14rem 0rem;
    background: #fff;
    img{
        position: absolute;
        left: 14rem;
        width: 17rem;
        height: 17rem;
    }
    span{
        font-family: PingFangSC, PingFang SC;
        font-weight: 600;
        font-size: 17rem;
        color: #333333;
    }
}
/deep/.van-switch--on{
    background-color: #000;
}
/deep/.van-address-edit__buttons .van-button--danger{
    color: #333333;
    background-color: #E0BFB1;
    border: 1px solid #E0BFB1;
}
</style>
